<template>
  <div>
    <v-container>
      <div v-if="!this.user.active">
        <v-card class="pa-6">
          <p class="title">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.hello"
              )
            }}
            {{ this.user.name }}
            {{ this.user.middle_name }}!
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.title"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.subtitle"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.description"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.informing"
              )
            }}
          </p>
        </v-card>
      </div>
      <div class="d-flex flex-column align-center" v-if="this.user.active">
        <h1>
          {{
            $vuetify.lang.t("$vuetify.view.auth.psychologist.Schedule.title")
          }}
        </h1>
        <p>
          {{
            $vuetify.lang.t("$vuetify.view.auth.psychologist.Schedule.subtitle")
          }}
        </p>
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.view.auth.psychologist.Schedule.description"
            )
          }}
        </p>
      </div>
      <template v-if="this.user.active">
        <div
          class="d-flex flex-column mt-5"
          v-for="(items, i) in timeSlots"
          v-bind:key="i"
        >
          <p class="title">{{ items.date.translated }}</p>
          <div class="d-flex flex-row flex-wrap justify-start">
            <div v-for="(item, n) in items.slots" v-bind:key="n">
              <v-tooltip
                :open-on-hover="item.is_booked"
                :open-on-click="false"
                :open-on-focus="false"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="ma-5" v-on="on" v-bind="attrs">
                    <v-btn
                      :disabled="initialDisabledBtn(item)"
                      class="btn-disable"
                      :class="initialBtnColor(item)"
                      @click="changeBtnsValue(items, i, n, $event)"
                      v-bind:key="item.id"
                      outlined
                      >{{ item.time }}</v-btn
                    >
                  </div>
                </template>
                <span v-show="item.patient_name"
                  >{{
                    $vuetify.lang.t(
                      "$vuetify.main.psychologistDashboard.schedule.client"
                    )
                  }}
                  {{ item.patient_name }}</span
                >
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>
      <div class="d-flex justify-end" v-if="this.user.active">
        <v-btn
          v-if="!isBtnVisible"
          @click="isBtnVisible = !isBtnVisible"
          outlined
          color="primary"
          >{{
            $vuetify.lang.t("$vuetify.view.auth.psychologist.Schedule.btnEdit")
          }}</v-btn
        >
        <v-btn
          v-if="isBtnVisible"
          @click="dataToSave()"
          outlined
          color="success"
          >{{
            $vuetify.lang.t("$vuetify.view.auth.psychologist.Schedule.btnSave")
          }}</v-btn
        >
      </div>
      <template v-if="this.user.active">
        <v-row justify="center">
          <h1>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.schedule.instruction"
              )
            }}
          </h1>
        </v-row>
        <div class="d-flex flex-column align-start">
          <div class="d-flex flex-row flex-wrap ma-5">
            <v-btn class="btn-disabled" outlined>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.example1"
                )
              }}</v-btn
            >
            <span class="align-self-center ml-5 font-weight-light"
              >{{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.exampleDesk11"
                )
              }}<span class="font-weight-bold">
                {{
                  $vuetify.lang.t(
                    "$vuetify.main.psychologistDashboard.schedule.exampleDesk12"
                  )
                }}</span
              >
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.exampleDesk13"
                )
              }}</span
            >
          </div>
          <div class="d-flex flex-row flex-wrap ma-5">
            <v-btn class="active">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.example2"
                )
              }}
            </v-btn>
            <span class="align-self-center ml-5 font-weight-light"
              >{{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.exampleDesk21"
                )
              }}
              <span class="font-weight-bold">{{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.exampleDesk22"
                )
              }}</span>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.exampleDesk23"
                )
              }}</span
            >
          </div>
          <div class="d-flex flex-row ma-5">
            <v-btn class="active-btn--green" outlined depressed>{{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.schedule.example3"
              )
            }}</v-btn>
            <span class="align-self-center ml-5 font-weight-light">{{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.schedule.exampleDesk31"
              )
            }}</span>
          </div>
          <div class="d-flex flex-row ma-5">
            <v-btn
              id="black-btn"
              class="custom-btn btn--disabled"
              disabled
              outlined
              depressed
            >
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.schedule.example4"
                )
              }}
            </v-btn>
            <span class="align-self-center ml-5 font-weight-light">{{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.schedule.exampleDesk41"
              )
            }}</span>
          </div>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "Schedule",
  components: {},
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.schedule"),
    };
  },
  data() {
    return {
      prevBtn: null,
      isBtnVisible: false,
      timeSlots: [],
      activeSlots: [],
      isBookedSlots: [],
      isBookedEdited: false,
      isActiveEdited: false,
      pole: "",
      selectedSlotId: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  beforeCreate() {
    this.$store.dispatch("user/getProfile", {});
  },
  beforeMount() {
    this.timeSlots = this.user.time_slots;

    this.user.time_slots.map((item) => {
      item.slots.map((slot) => {
        if (slot.is_booked) {
          this.isBookedSlots.push({
            ids: slot.id,
          });
        }

        if (slot.available && !slot.is_booked) {
          this.activeSlots.push({
            ids: slot.id,
          });
        }
      });
    });
  },
  methods: {
    getPayload(options) {
      let payload = [];

      switch (options) {
        case "available":
          this.activeSlots.map((el) => {
            payload.push(el.ids);
          });
          return payload;
        case "booked":
          return this.selectedSlotId;
      }
    },
    async dataToSave() {
      this.isBtnVisible = !this.isBtnVisible;

      await this.$store
        .dispatch("user/allowTimeSlotByPsyh", {
          ids: this.getPayload("available"),
        })
        .then((response) => {
          if (response.status === 200) {
            Vue.swal({
              target: document.getElementById("main"),
              text: response.data.message,
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
            this.$store.dispatch("user/getProfile", {});
          }
        });
    },
    async changeBtnsValue(items, indexFromUserSelect, itemIndex, event) {
      let available = items.slots[itemIndex].available;
      let is_booked = items.slots[itemIndex].is_booked;
      this.selectedSlotId = items.slots[itemIndex].id;
      this.setActiveSlot(items.slots[itemIndex], false);

      if (is_booked) {
        Vue.swal({
          target: document.getElementById("app"),
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.schedule.swallCancelSessionByPsychologistTitle"
          ),
          showDenyButton: true,
          confirmButtonText: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.schedule.swallYes"
          ),
          denyButtonText: this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.schedule.swallNo"
          ),
        }).then(async (result) => {
          if (result.isConfirmed) {
            Vue.swal({
              target: document.getElementById("main"),
              text: this.$vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.schedule.swallCancelSessionByPsychologist"
              ),
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              didOpen: () => {
                Vue.swal.showLoading();
              },
              didClose: () => {},
            });
            await this.$store
              .dispatch("user/cancelTimeSlotByPsyh", {
                id: this.getPayload("booked"),
              })
              .then((response) => {
                if (response.status === 200) {
                  Vue.swal({
                    target: document.getElementById("main"),
                    text: response.data.message,
                    icon: "success",
                    timer: 4000,
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                  });

                  this.setActiveSlot(items.slots[itemIndex], true);

                  Object.assign(
                    this.timeSlots[indexFromUserSelect].slots[itemIndex],
                    {
                      is_booked: false,
                      available: true,
                      patient_name: "",
                    }
                  );
                  this.changeBtnColorScheme(items, event);
                  this.isBtnVisible = !this.isBtnVisible;
                  this.$store.dispatch("user/getProfile", {});
                } else {
                  Vue.swal({
                    target: document.getElementById("main"),
                    text: response.data.message,
                    icon: "error",
                    timer: 4000,
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                  });
                }
              });
          }

          if (result.isDenied) {
            Object.assign(
              this.timeSlots[indexFromUserSelect].slots[itemIndex],
              {
                is_booked: true,
                available: false,
                patient_name: items.slots[itemIndex].patient_name,
              }
            );
          }
        });
      }

      if ((available && !available) || !is_booked) {
        Object.assign(this.timeSlots[indexFromUserSelect].slots[itemIndex], {
          available: !available,
        });
        this.changeBtnColorScheme(items, event);
      }
    },
    changeBtnColorScheme(item, event) {
      if (event.target.tagName === "SPAN") {
        this.prevBtn = event.target.parentElement;

        if (
          event.target.parentElement.classList.contains("active-btn--green")
        ) {
          return event.target.parentElement.classList.toggle(
            "active-btn--green"
          );
        } else if (event.target.parentElement.classList.contains("active")) {
          return event.target.parentElement.classList.toggle("active");
        } else {
          return event.target.parentElement.classList.toggle("active");
        }
      } else {
        this.prevBtn = event.target;

        if (event.target.classList.contains("active-btn--green")) {
          return event.target.classList.toggle("active-btn--green");
        } else if (event.target.classList.contains("active")) {
          return event.target.classList.toggle("active");
        } else {
          return event.target.classList.toggle("active");
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    setActiveSlot(item, isBooked) {
      if (item.is_booked && item.psychologist_can_cancel && isBooked) {
        if (
          this.isBookedSlots.find((el) => {
            return el.ids === item.id;
          })
        ) {
          this.isBookedSlots = this.isBookedSlots.filter((el) => {
            el.ids !== item.id;
          });

          return this.activeSlots.push({
            ids: item.id,
          });
        } else {
          this.isBookedSlots.push({
            ids: item.id,
          });
        }
      }

      if (
        this.activeSlots.find((el) => {
          return el.ids === item.id;
        })
      ) {
        this.activeSlots = this.activeSlots.filter((el) => {
          return el.ids !== item.id;
        });
      } else {
        if (item.is_booked) {
          return;
        } else {
          this.activeSlots.push({
            ids: item.id,
          });
        }
      }
    },
    initialBtnColor(item) {
      if (item.is_booked && item.psychologist_can_cancel) {
        return "active-btn--green";
      } else if (item.available && !item.is_booked) {
        return "active";
      } else if (item.is_booked && !item.psychologist_can_cancel) {
        return "custom-btn";
      } else {
        return "";
      }
    },
    initialDisabledBtn(item) {
      if (this.isBtnVisible) {
        if (!item.psychologist_can_cancel && item.is_booked) {
          return !item.psychologist_can_cancel;
        }
      }
      return !this.isBtnVisible;
    },
  },
};
</script>

<style lang="sass" scoped>
.btn-disable
  color: #1B1B1B

.active
  color: #989898 !important
  background-color: #C4C4C4 !important

.active-btn--green
  color: #ffffff !important
  background-color: #00A4B7 !important

.custom-btn
  color: #ffffff
  background-color: #003D44 !important
#black-btn
  color: #ffffff !important
</style>
